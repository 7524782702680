import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import logo from "../images/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faCaretRight, faCaretLeft, faTimes } from '@fortawesome/free-solid-svg-icons'

class Header extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      menuActive: false,
      moreInfoActive: false,
      dropdownActive: false,
      dropdownId: null,
    }


    //bind this
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleMoreInfo = this.toggleMoreInfo.bind(this)
  }


  toggleMenu() {
    this.setState({'menuActive': !this.state.menuActive})
  }

  toggleMoreInfo() {
    this.setState({'moreInfoActive': !this.state.moreInfoActive})
  }
  activateDropdown(id) {
    this.setState({'dropdownActive': true})
    this.setState({'dropdownId': id})
  }
  deactivateDropdown() {
    this.setState({'dropdownActive': false})
  }
  dropdownSetClass(id) {
    if(this.state.dropdownActive && this.state.dropdownId === id) {
      return 'active'
    } else {
      return ''
    }
  }
  navClass() {
    if(this.state.dropdownActive) {
      return 'dropdown-active'
    } else {
      return ''
    }
  }
  render() {
    let Nav = (
      <>
      <StaticQuery 
        query={graphql`
          query {
            graphAPI {
              navigation {
                linkList {
                  ... on GRAPHAPI_ComponentMetaLink {
                    id
                    __typename
                    title
                    url
                  }
                  ... on GRAPHAPI_ComponentMetaDropdown {
                    id
                    __typename
                    title
                    links {
                      __typename
                      title
                      url
                      id
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (<>
          {data.graphAPI.navigation.linkList.map( link => {
            if(link.__typename === "GRAPHAPI_ComponentMetaLink") {
              return (
                <Link key={link.id} to={link.url} className="nav-item">{link.title}</Link>
              )
            }
            else if(link.__typename === "GRAPHAPI_ComponentMetaDropdown") {
              return (
                  <>
                  <button key={link.id} onClick={() => {this.activateDropdown(link.id)}} className="nav-item">{link.title} <span className="icon"><FontAwesomeIcon icon={faCaretRight} /></span></button>
                  <div className={`dropdown-set ${this.dropdownSetClass(link.id)}`}>
                    <button onClick={() => {this.deactivateDropdown()}} className="nav-item">
                      <span className="icon"><FontAwesomeIcon icon={faCaretLeft} /></span> {link.title}</button>
                    {link.links.map(sublink => (
                      <Link key={sublink.id} to={sublink.url} className="nav-item">{sublink.title}</Link>
                    ))}
                  </div>
                  </>
              )
            }
          })}
        </>)} 
        />
      </>
    )

    return (
    <>
    <div className={`mobile-menu`}>
      <Link to="/" className="aside-left">
        <img className="logo" src={logo} alt="" />
      </Link>
      <div className="aside-right" onClick={this.toggleMenu}>
          <div className={`menu ${this.state.menuActive ? 'active' : ''}`}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
          </div>
      </div>
    </div>
    <header className={`${this.state.menuActive ? 'active' : ''}`}>
    <Link to="/" className="aside-left">
      <img className="logo" src={logo} alt="" />
    </Link>
      <div className="closebutton" onClick={this.toggleMenu}>
          {/* <span className="icon"><FontAwesomeIcon icon={faTimes} /></span> */}
          CLOSE
      </div>
        <nav className={`${this.navClass()}`}>
          {Nav}
        </nav>
    {/* <div className="social-links">
        <a target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/cypresscreekfire/" className="">
          <span className="icon"><FontAwesomeIcon icon={faFacebook} /></span>
        </a>
        <a target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/cypresscreekfd/" className="">
          <span className="icon"><FontAwesomeIcon icon={faInstagram} /></span>
        </a>
    </div> */}
    </header>
    </>
  )}
}


export default Header
